<template>
  <div>
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-md-2 col-lg-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>

        <div class="col-md-10 col-lg-10 col-sm-12">
          <div class="card">

            <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
              <div class="anan-tabs__nav">
                <div
                  class="anan-tabs__nav-warp d-flex justify-content-between align-items-center flex-wrap my-1 px-2"
                  style="margin-top: 5px;"
                >

                  <div class="">
                    <h3 class="font-weight-bolder">
                      รหัสพัสดุ
                    </h3>

                  </div>

                  <div class="d-flex align-items-center w-lg-100">
                    <div
                      class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25"
                    >
                      <input
                        v-model="search_val"
                        type="text"
                        :placeholder="$t('search')"
                        class="anan-input__input"
                        @keyup.enter="getTracking()"
                      >
                      <div class="anan-input__suffix">
                        <button
                          type="button"
                          class="anan-button anan-button--normal anan-button--primary"
                          @click="getTracking()"
                        >
                          <i class="fal fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="mt-1">
                <router-link
                  :to="{name : 'member-parcelcode-create'}"
                  class="btn btn-primary ml-1"
                  style="width: 150px;"
                >
                  <i class="far fa-plus mr-25" /> เพิ่มรหัสพัสดุ
                </router-link>
              </div> -->

            </div>

            <b-overlay
              :show="showOver"
              variant="transparent"
              opacity="0.99"
              blur="5px"
              rounded="sm"
            >
              <template #overlay>
                <div class="text-center">
                  <p id="cancel-label">
                    กรุณารอสักครู่...
                  </p>
                </div>
              </template>
              <div class="pb-1 px-2">
                <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :items="itemarr"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                >
                  <template #cell(detail)="data">
                    <div style="max-width: 300px !important;">
                      <div class="d-flex">
                        <div class="w-50">
                          <p class="mb-0 text-right mr-50">
                            {{ $t("key-22") }} :
                          </p>
                        </div>
                        <div class="w-50">
                          <small class="text-black">
                            {{ data.item.tag_id ? data.item.tag_id : "-" }}
                          </small>
                        </div>
                      </div>
                      <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t("systemOrder") }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <b-link
                          v-if="data.item.order_text"
                          :to="{
                            name: 'order-detail',
                            params: { id: data.item.order_id },
                          }"
                          class="mb-0"
                        >
                          ORD-{{data.item.order_text}}
                        </b-link>
                        <small v-else>-</small>
                      </div>
                    </div>

                      <div class="d-flex">
                        <div class="w-50">
                          <p class="mb-0 text-right mr-50">
                            {{ $t("parcelCode") }} :
                          </p>
                        </div>
                        <div class="w-50">
                          <b-link
                            :to="{
                              name: 'tracking-edit',
                              params: { id: data.item._id },
                            }"
                            class="mb-0"
                          >
                            {{ data.item.postnumber }}
                          </b-link>
                          <i
                            v-clipboard:copy="data.item.postnumber"
                            :class="
                              iconClass[data.item.postnumber]
                                ? 'fal fa-clipboard-check text-success'
                                : 'fal fa-copy text-primary'
                            "
                            @click="handleCopy(data.item.postnumber)"
                          />
                        </div>
                      </div>

                      <div class="d-flex">
                        <div class="w-50">
                          <p class="mb-0 text-right mr-50">
                            {{ $t("lotSequence") }} :
                          </p>
                        </div>
                        <div class="w-50">
                          <p
                            class="mb-0 text-black badge badge-warning"
                            :class="{
                              'mb-0 text-black badge badge-danger':
                                data.item.transportType === 2,
                            }"
                          >
                            {{ checktype(data.item.transportType) }}
                          </p>
                          <!-- <b-link :to="{ name: 'asdsad' }"> -->
                          {{ data.item.lot_name ? data.item.lot_name : "-" }}
                          <!-- </b-link> -->
                          <small>/{{
                            data.item.lot_order ? data.item.lot_order : "-"
                          }}</small>
                        </div>
                      </div>

                      <div class="d-flex">
                        <div class="w-50">
                          <p class="mb-0 text-right mr-50">
                            {{ $t('woodPacking') }} :
                          </p>
                        </div>
                        <div class="w-50">
                          <span
                          style="font-size: 11px;"
                          class="badge badge-success"
                          v-if="data.item.packbox"
                        >{{ $t('activate') }}</span>
                        <span
                          style="font-size: 11px;"
                          class="badge badge-danger"
                          v-else
                        >{{ $t('key-328') }}</span>
                        </div>
                      </div>

                      <div class="d-flex">
                        <div class="w-50">
                          <p class="mb-0 text-right mr-50">
                            {{ $t('checkOrder') }} :
                          </p>
                        </div>
                        <div class="w-50">
                          <span
                            style="font-size: 11px;"
                            class="badge badge-success"
                            v-if="data.item.checkproduct"
                          >{{ $t('activate') }}</span>
                          <span
                            style="font-size: 11px;"
                            class="badge badge-danger"
                            v-else
                        >{{ $t('key-328') }}</span>
                        </div>
                      </div>

                      <div class="d-flex">
                        <div class="w-50">
                          <p class="mb-0 text-right mr-50">
                            {{ $t("type") }} :
                          </p>
                        </div>
                        <div class="w-50">
                          <p class="mb-0">
                            {{ checkproduct_type(data.item.product_type) }}
                          </p>
                        </div>
                      </div>

                      <div class="d-flex">
                        <div class="w-50">
                          <p class="mb-0 text-right mr-50">
                            {{ $t("mnemonic") }} :
                          </p>
                        </div>
                        <div class="w-50">
                          <p
                            class="mb-0 text-truncate "
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="data.item.keyword ? data.item.keyword : '-'"
                          >
                            {{ data.item.keyword ? data.item.keyword : "-" }}
                          </p>
                        </div>
                      </div>

                      <div class="d-flex">
                        <div class="w-50">
                          <p class="mb-0 text-right mr-50">
                            {{ $t("remarks") }} :
                          </p>
                        </div>
                        <div class="w-50">
                          <p class="mb-0">
                            {{ data.item.remark ? data.item.remark : "-" }}
                          </p>
                        </div>
                      </div>
                      <div
                      v-if="data.item.claim_status"
                      class="d-flex text-danger border-top mt-25 pt-25"
                    >
                      <div class="w-50">
                        <p class="mb-0 text-right mr-50">
                          {{ $t('key-23') }} :
                        </p>
                      </div>
                      <div class="w-50">
                        <p class="mb-0">
                          {{ data.item.claim_detail ? data.item.claim_detail: '-' }}
                        </p>
                        <p class="mb-0">
                          {{ $t('quantity') }} : {{ data.item.claim_amount ? Commas(data.item.claim_amount): '0' }} ฿
                        </p>
                      </div>
                    </div>
                    </div>
                  </template>

                  <template #cell(calPrice)="data">
                    <div class="d-flex">
                      <div class="w-50">
                        <div class="mb-0 text-right mr-50">
                          <small>{{ $t("dimensionsCm") }} :</small>
                        </div>
                      </div>
                      <div class="w-50">
                        <small class="mb-0">
                          ({{ data.item.width }} x {{ data.item.long }} x
                          {{ data.item.height }}) x {{ data.item.product_amount }}
                        </small>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <div class="mb-0 text-right mr-50">
                          <small>{{ $t("weightKilo") }} :</small>
                        </div>
                      </div>
                      <div class="w-50">
                        <small class="mb-0">
                          ({{ data.item.weight }}) x
                          {{ data.item.product_amount }}
                        </small>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <div class="mb-0 text-right mr-50">
                          <small>{{ $t("quantity") }} :</small>
                        </div>
                      </div>
                      <div class="w-50">
                        <small class="mb-0">
                          {{ data.item.product_amount }}
                        </small>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <div class="mb-0 text-right mr-50">
                          <small>{{ checkcharge_per(data.item) }} :</small>
                        </div>
                      </div>
                      <div class="w-50">
                        <p class="mb-0">
                          {{ data.item.charge_per_save }} ฿
                        </p>
                      </div>
                    </div>
                  </template>

                  <template #cell(tranStatus)="data">
                    <app-timeline>
                      <!-- <app-timeline-item
                    v-if="data.item"
                    :title="`: นำออกแล้ว`"
                    variant="success"
                  /> -->
                      <app-timeline-item
                        v-if="data.item.paymentday"
                        :title="`${data.item.paymentday} :` + $t('payShipping')"
                        variant="success"
                      />
                      <app-timeline-item
                        v-if="data.item.out_thaiwarehouse"
                        :title="
                          `${data.item.out_thaiwarehouse}: ` +
                            $t('arrivedThailand')
                        "
                        variant="success"
                      />

                      <app-timeline-item
                        v-if="data.item.come_thaiwarehouse"
                        :title="
                          `${data.item.come_thaiwarehouse}: ` +
                            $t('arrivedThaiWarehouse')
                        "
                        variant="success"
                      />

                      <app-timeline-item
                        v-if="data.item.out_chinesewarehouse"
                        :title="
                          `${data.item.out_chinesewarehouse}: ` +
                            $t('exitWarehouse')
                        "
                        variant="success"
                      />

                      <app-timeline-item
                        v-if="data.item.come_chinesewarehouse"
                        :title="
                          `${data.item.come_chinesewarehouse} : ` +
                            $t('arrivedChinaWarehouse')
                        "
                        variant="success"
                      />
                    </app-timeline>
                  </template>

                  <template #cell(bill)="data">
                    {{
                      data.item.itemnumber_payment
                        ? data.item.itemnumber_payment
                        : $t("none")
                    }}
                  <!-- <b-link
                  :to="{name : 'admin-tracking-edit',}"
                  class="mb-0"
                >
                  'ไม่มี'
                </b-link> -->
                  </template>
                  <template #cell(memberCode)="data">
                    <b-link
                      v-if="data.item.user_member_check && data.item.user_member"
                      :to="{
                        name: 'member-user-edit',
                        params: { id: data.item.user_member_id },
                      }"
                      class="mb-0"
                    >
                      {{ data.item.user_member }}
                    </b-link>
                    <span v-else> {{ $t('none') }} </span>
                    <!-- {{ data.item.user_member_check ? data.item.user_member: 'ไม่มี'}} -->
                  </template>

                  <template #cell(action)="data">
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-50 text-primary cursor-pointer"
                      @click="EditType(data.item)"
                    />

                    <feather-icon
                      v-if="!data.item.come_chinesewarehouse && !data.item.out_chinesewarehouse && !data.item.come_thaiwarehouse"
                      icon="TrashIcon"
                      class="text-danger cursor-pointer"
                      @click="DeleteData(data.item)"
                    />
                  </template>
                </b-table>

                <div class="demo-spacing-0 d-flex justify-content-end mb-2">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                    @input="getTracking()"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </b-overlay>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BLink,
  BFormSelect,
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import SideBar from '../component/SideBar.vue'

export default {
  components: {
    BTable,
    BLink,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    AppTimeline,
    AppTimelineItem,
    // ThisHeader,
    BOverlay,
    SideBar,
  },

  data() {
    return {
      message: '78708961999066',
      totalRows: 0,
      isActive: 'all',
      //   required,
      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',
      currentPage: 1,
      perPage: 10,
      search: null,
      imagePreviewUrls: [],
      iconClass: {},
      showOver: false,
      itemarr: [],
      search_val: null,
    }
  },

  computed: {
    fields() {
      return [
        {
          key: 'detail',
          label: this.$t('details'),
          thStyle: { width: '25%' },
          thClass: 'text-center',
        },
        {
          key: 'memberCode',
          label: this.$t('memberCode'),
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'calPrice',
          label: this.$t('calculatePrice'),
          sortable: false,
          thStyle: { width: '20%' },
          thClass: 'text-center',
        },
        {
          key: 'tranStatus',
          label: this.$t('shippingStatus'),
          sortable: false,
          thStyle: { width: '20%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'bill',
          label: this.$t('issueBill'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: this.$t('manage'),
          sortable: false,
          thStyle: { width: '5%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },

  mounted() {
    this.getTracking()
    // this.getData('all')
  },

  methods: {
    Claim() {
      this.$swal({
        title: '<h3 style="color: #141414">ทำรายการ Claim สินค้า</h3>',
        icon: 'warning',
        showCancelButton: true,
        text: 'คุณต้องเคลมสินค้าหรือไม่ ?',
        confirmButtonText: 'Yes, claim it!',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: '<h3 style="color: #141414">สำเร็จ</h3>',
            html: '<p style="color: #141414">ทำรายการเคลมสินค้าสำเร็จ</p>',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    },
    async getTracking() {
      this.showOver = true
      try {
        const params = {
          search: this.search_val,
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/Tracking/getcustomer', { params })
        this.itemarr = res.data
        this.totalRows = res.total
        this.showOver = false
        // console.log(this.itemarr)

        // this.itemss = res
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    checktype(item) {
      if (item === 1) {
        return this.$t('truck')
      }
      if (item === 2) {
        return this.$t('ship')
      }
      return ''
    },
    checkproduct_type(item) {
      if (item) {
        if (this.$i18n.locale === 'th') {
       return item.pro_name
      } else if (this.$i18n.locale === 'en') {
        return item.pro_name_en
      } else if (this.$i18n.locale === 'cn') {
        return item.pro_name_cn
      }
      } else {
        return '-'
      }
      
    },
    checkcharge_per(item) {
      if (item.charge_per_save === item.charge_per_kilo) {
        return this.$t('calculateByKilo')
      }
      return this.$t('key-98')
    },
    removeImage(url) {
      this.imagePreviewUrls = this.imagePreviewUrls.filter(
        item => item !== url,
      )
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            customerCode: this.customerCode,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixNum: this.prefixNum,
          }
          this.$http.post('groupbyuser/list/create', params).then(() => {
            this.getData(this.isActive)
            this.$bvToast.toast('เพิ่มข้อมูลสำเร็จ', {
              title: 'สำเร็จ',
              variant: 'success',
              solid: true,
              autoHideDelay: 1500,
            })
          })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    getDataStorage() {
      const data = JSON.parse(localStorage.getItem('data'))
      this.imagePreviewUrl = data.imagePreviewUrl
      this.imagePreviewUrls = data.imagePreviewUrls
      this.colorBG = data.colorBG
      this.colorText = data.colorText
      this.gradientColor = data.gradientColor
    },
    handleFileChanges(event) {
      const { files } = event.target
      this.imagePreviewUrls = []
      console.log(this.imagePreviewUrls)
      //   add to local storage
      localStorage.setItem(
        'imagePreviewUrls',
        JSON.stringify(this.imagePreviewUrls[0]),
      )

      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imagePreviewUrls.push(e.target.result)
        }
        reader.readAsDataURL(files[i])
      }
    },
    addMoreImages() {
      this.$refs.fileInput.click()
      console.log(this.imagePreviewUrls)
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      this.imgBackground = file
      this.previewImage(file)
    },
    previewImage(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.imagePreviewUrl = reader.result
      }
      reader.readAsDataURL(file)
    },
    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    // async getData(type) {
    //   this.isActive = type
    //   try {
    //     const { data: res } = await this.$http.get('/groupbyuser/list/index')
    //     this.items = res
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'member-parcelcode-edit', params: { id: data._id } })
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
          title: 'ยืนยันการลบข้อมูล',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
            }
            this.$http.post('/Tracking/removePostnumber', params).then(() => {
              this.getTracking()
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleCopy(item) {
      if (item) {
        this.$set(this.iconClass, item, true)

        setTimeout(() => {
          this.$set(this.iconClass, item, false)
        }, 3500)
      }
    },
  },

}
</script>

<style>
.item-detail {
    display: flex;
    /* justify-content: end; */
    align-items: center;
}

.t-width {
    min-width: 1100px !important;
}

</style>
